<template>
  <a-modal :visible="showDialog" title="新建库存" width="680px" :maskClosable="false" :closable="false">
    <a-form-model layout="vertical" ref="form" :model="form" :rules="rules" class="edp-form">
      <div class="line-2">
        <a-form-model-item label="产品名称" prop="product_name" style="margin-bottom: 12px;">
          <a-select
              show-search
              :value="form.product_name"
              placeholder="请输入产品名称"
              optionFilterProp="children"
              @change="handleChange"
          >
            <a-select-option v-for="d in data" :key="d.oid" :value="d.oid">
              {{ d.product_name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="产品编号" prop="product_code" style="margin-bottom: 12px;">
          <a-input v-model="form.product_code" disabled></a-input>
        </a-form-model-item>
        <a-form-model-item label="品牌" prop="band">
          <a-input v-model="form.band" disabled></a-input>
        </a-form-model-item>
        <a-form-model-item label="规格型号" prop="tech_info">
          <a-input v-model="form.tech_info" disabled></a-input>
        </a-form-model-item>
      </div>
      <div class="tip">库存信息</div>
      <div class="line-2 bottom-box">
        <a-form-model-item label="库存数量" prop="store_count">
          <a-input v-model="form.store_count"></a-input>
        </a-form-model-item>
        <a-form-model-item label="安全库存" prop="safe_count">
          <a-input v-model="form.safe_count"></a-input>
        </a-form-model-item>
      </div>
    </a-form-model>
    <template #footer>
      <div class="modal-footer" style="display: flex; align-items: center">
        <a-checkbox v-model="form.next" style="margin-right: auto;">
          继续创建下一个
        </a-checkbox>
        <a-space>
          <a-button @click="closeDialog">
            取消
          </a-button>
          <a-button type="primary" @click="submitInventory">
            确定
          </a-button>
        </a-space>
      </div>
    </template>
  </a-modal>
</template>

<script>

import {fetch} from "@/utils/request";

export default {
  props: {
    showDialog: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      data: [],
      form: {
        oid: '',
        product_name: '',
        product_code: '',
        band: '',
        tech_info: '',
        next: false,
        // 库存数量
        store_count: '',
        // 安全库存数量
        safe_count: '',
      },
      rules: {
        product_name: [
          { required: true, message: "请填写产品名称", trigger: 'change' },
        ],
        // product_code: [
        //   { required: true, message: "请填写产品编号", trigger: 'blur' },
        // ],
        // band: [
        //   { required: true, message: "请填写品牌", trigger: 'blur' },
        // ],
        // tech_info: [
        //   { required: true, message: "请填写规格型号", trigger: 'blur' },
        // ],
        store_count: [
          { required: true, message: "请填写库存数量", trigger: 'change' },
        ],
        safe_count: [
          { required: true, message: "请填写安全库存数量", trigger: 'change' },
        ],
      }
    }
  },
  created() {
    this.getData()
  },
  methods: {
    // 获取搜索内容
    async getData() {
      const res = await fetch('post', 'pro/retrieve', {
        page_index: 1,
        page_size: 9999
      })
      if (res.status === 0) {
        this.data = res.data.datas
      }
    },
    // 选择值
    handleChange(value) {
      const selectedOption = this.data.find(d => d.oid === value)
      if (selectedOption) {
        this.$set(this.form, 'product_name', selectedOption.product_name)
        this.$set(this.form, 'product_code', selectedOption.product_code)
        this.$set(this.form, 'band', selectedOption.band)
        this.$set(this.form, 'tech_info', selectedOption.tech_info)
        this.$set(this.form, 'oid', selectedOption.oid)
      }
    },

    // 关闭弹窗
    closeDialog() {
      this.$emit('update:showDialog', false)
      this.form = {}
      this.data = []
      this.$refs.form.resetFields()
    },

    // 提交表单
    submitInventory() {
      this.$refs.form.validate(async valid => {
        if (valid) {
          const { oid, safe_count, store_count } = this.form
          const res = await fetch('post', '/whs/create', {
            prooid: oid,
            store_count,
            safe_count
          })
          if (res.status === 0) {
            this.$parent.getInventoryData()
            if (this.form.next) {
              this.$refs.form.resetFields()
              this.form = {}
            } else {
              this.closeDialog()
            }
          } else {
            this.$message.error(res.message)
          }

        } else {
          return false
        }
      })
    }
  },
}
</script>

<style lang="less" scoped>
.tip {
  padding-left: 24px;
  margin: -12px -24px 20px;
  background-color: #F8F8F8;
}

.bottom-box {
  .ant-form-item {
    margin-bottom: 0;
  }
}
</style>
