<template>
  <div class="app-main-content">
    <div class="left-category">
      <card class="stage-left">
        <template #title>
          <div class="edp-card-head-left-title" style="cursor: pointer" @click="toTop">产品类别</div>
        </template>
        <edp-tree ref="edpTree" :data="[ ...cat.product_category]" @change="clickTree" row-key="oid" :flag="true"
                  @fromSun="getCatChildren">
          <template v-slot="{ row, open, fun }">
            <div class="tree-row">
              <div class="tree-row-info">
                <img :src='$toUrl("folder_open.png")' class="dir" alt="">
                {{ row.cat_name }}
              </div>
              <div class="tree-row-dropdown" @click.stop>
                <a-dropdown>
                  <a style="color: #08f;" class="ant-dropdown-link" @click="e => e.preventDefault()">
                    <a-icon type="ellipsis" />
                  </a>
                  <a-menu slot="overlay">
                    <a-menu-item>
                      <a href="javascript:;" @click="addCat(row, false, open, fun)">新增</a>
                    </a-menu-item>
                    <a-menu-item>
                      <a href="javascript:;" @click="addCat(row, true)">修改</a>
                    </a-menu-item>
                    <a-menu-item>
                      <a href="javascript:;" @click="delCat(row)">删除</a>
                    </a-menu-item>
                  </a-menu>
                </a-dropdown>
              </div>
            </div>
          </template>
        </edp-tree>
      </card>

    </div>
    <div class="right-table">
      <card body-class="overflow-hidden page-card-body" style="height: 100%;" title="库存">
        <template #tools>
          <div class="left">
            <a-button type="primary" @click="showAddInventoryDialog">
              <i class="iconfont icon-jia" style="margin-right: 4px;"></i>
              新增
            </a-button>
          </div>
          <div class="right">
            <div class="item">
              <span class="label">排序</span>
              <a-select @change="changeInventoryData" class="sort-select" placeholder="创建时间"
                        v-model="orderby">
                <a-select-option :value="item.value" v-for="(item, index) in orderbyList" :key="index">{{
                    item.name
                  }}
                </a-select-option>
              </a-select>
            </div>
            <div class="item">
              <span class="label">搜索</span>
              <a-input class="search-input" v-model="keywords" placeholder="请输入关键字">
                <a-icon slot="prefix" type="search" />
              </a-input>
            </div>
            <div class="item">
              <a-button class="search" @click="searchInventoryData">查询</a-button>
            </div>
            <div class="item">
              <a-dropdown>
                <img class="more" :src="$toUrl('more.png')">
                <a-menu slot="overlay" @click="selectActionMenu">
                  <a-menu-item key="download">模板</a-menu-item>
                  <a-menu-item key="import">导入</a-menu-item>
                  <a-menu-item key="export">导出</a-menu-item>
                  <a-menu-item key="compare">对比</a-menu-item>
                </a-menu>
              </a-dropdown>
            </div>
          </div>
        </template>
        <div class="body-flex">
          <div class="body-left-table scrollY">
            <a-table style="min-width: 900px; box-sizing: border-box" tableLayout="fixed" :data-source="inventoryData"
                     rowKey="oid"
                     :pagination="pagination" @change="paginationChange">
              <a-table-column title="产品编号" data-index="product_code" width="140px"></a-table-column>
              <a-table-column title="产品名称">
                <template slot-scope="scope, row">
                  <div class="edp-title" style="display: flex;align-items: center;">
                    <img style="margin-top: 1px" v-if="Number(row.store_count)===0" :src="$toUrl('product_inventory_blue.png')" alt="">
                    <img style="margin-top: 1px" v-else-if="Number(row.store_count)<Number(row.safe_count)"
                         :src="$toUrl('product_inventory_red.png')" alt="">
                    <img style="margin-top: 1px" v-else-if="Number(row.store_count)>=Number(row.safe_count)"
                         :src="$toUrl('product_inventory_green.png')" alt="">
                    <span class="name">{{ row.product_name }}</span>
                    <a-tooltip>
                      <template slot="title">修改</template>
                      <!--<i class="iconfont icon-wenbenshuru hidden" style="margin-top: 3px"-->
                      <!--   @click="showTaskDetailDialog(row)"></i>-->
                      <img class="hidden" style="width: auto;height: 14px;line-height: 14px;cursor: pointer;margin-top: 1px;"
                           :src="$toUrl('purchase_edit.png')" @click="showTaskDetailDialog(row)" />
                    </a-tooltip>
                    <a-tooltip>
                      <template slot="title">删除</template>
                      <!--<img class="hidden" style="width: auto;height: 14px;line-height: 14px;cursor: pointer"-->
                      <!--     :src="$toUrl('delete2.png')"-->
                      <!--     alt="" @click="deleteInventory(row.oid)">-->
                      <img class="hidden" style="width: auto;height: 14px;line-height: 14px;cursor: pointer;margin-top: 1px;"
                           :src="$toUrl('delete3.svg')" @click="deleteInventory(row.oid)" />
                    </a-tooltip>
                  </div>
                </template>
              </a-table-column>
              <a-table-column title="供应商" data-index="company_name" width="250px"></a-table-column>
              <a-table-column title="供应商编号" data-index="company_no" width="150px"></a-table-column>
              <a-table-column title="品牌" data-index="band" width="150px"></a-table-column>
              <a-table-column title="型号" data-index="tech_info" width="150px"></a-table-column>
              <a-table-column title="安全库存" width="100px">
                <template slot-scope="scope, row">
                  <edp-input
                      style="width: 100%"
                      size="mini"
                      v-model="row.safe_count"
                      :db="dbs.prjIssue"
                      field-name="safe_count"
                      :oid="row.oid"
                      :pro-oid="row.prooid"
                  ></edp-input>
                  <!--<span>{{ Number(row.safe_count).toFixed(2) }}</span>-->
                </template>
              </a-table-column>
              <a-table-column title="库存数量" width="100px">
                <template slot-scope="scope, row">
                  <edp-input
                      style="width: 100%"
                      size="mini"
                      v-model="row.store_count"
                      :db="dbs.prjIssue"
                      field-name="store_count"
                      :oid="row.oid"
                      :pro-oid="row.prooid"
                  ></edp-input>
                </template>
              </a-table-column>
              <a-table-column title="更新时间" width="130px">
                <template slot-scope="scope, row">
                  <span>{{ dayjs(row.update_time).format('YYYY-MM-DD') }}</span>
                </template>
              </a-table-column>
            </a-table>
          </div>
        </div>
      </card>
    </div>
    <a-modal v-model="visible" :title="form.title" width="680px" class="uploadModal">
      <a-form-model layout="vertical" ref="form" :model="form" :rules="rules" class="edp-form">
        <div class="line-2">
          <a-form-model-item label="类别名称" prop="cat_name">
            <a-input v-model="form.cat_name"></a-input>
          </a-form-model-item>
          <a-form-model-item label="编号" prop="cat_code">
            <a-input v-model="form.cat_code"></a-input>
          </a-form-model-item>
        </div>
      </a-form-model>
      <template #footer>
        <div class="modal-footer">
          <a-space>
            <a-button @click="visible = false">
              取消
            </a-button>
            <a-button type="primary" @click="submit">
              确定
            </a-button>
          </a-space>
        </div>
      </template>
    </a-modal>
    <add-inventory-dialog ref="addInventoryDialog" :show-dialog.sync="showAddDialog" @submit="addSubmit" />
    <task-detail-dialog ref="taskDetailDialog" :show-dialog.sync="showTaskDialog" :prooid.sync="projectOid"
                        @getPageData="getInventoryData" />
    <compare-results-dialog ref="compareResultsDialog" :show-dialog.sync="showCompareDialog" />
    <import-file url="/whs/import" ref="importData" @response="uploadResponse"></import-file>
    <import-file url="/whs/import/compare" ref="importCompareData" @response="compareUploadResponse"></import-file>
  </div>
</template>

<script>
import EdpTree from "@/components/edpTree.vue";
import AddInventoryDialog from "@/view/product/components/addInventoryDialog.vue";
import ImportFile from "@/components/importFile.vue";
import { getTemplate } from "@/utils/helpFunc";
import { fetch } from "@/utils/request";
import TaskDetailDialog from "@/view/product/components/taskDetailDialog.vue";
import EdpInput from "@/components/e-form/edp-input.vue";
import { FieldUpdateDb } from "@/utils/FieldUpdate";
import CompareResultsDialog from "@/view/product/components/compareResultsDialog.vue";
import dayjs from "dayjs";


export default {
  components: { CompareResultsDialog, EdpInput, TaskDetailDialog, ImportFile, AddInventoryDialog, EdpTree },
  data() {
    return {
      dbs: {
        prjIssue: new FieldUpdateDb("/whs/update/onefield", "whs_core")
      },
      visible: false,
      form: {
        title: '新增产品类目',
        cat_name: '',
        cat_code: '',
        cat_type: 'product_category',
        field_type: '0',
        level: '',
        routemap: ''
      },
      rules: {
        cat_code: [{ required: true, message: "请输入编号", trigger: 'change' }],
        cat_name: [{ required: true, message: "请输入名称", trigger: 'change' }]
      },
      backup: {},
      editFolder: {},
      addOpen: function () {
      },
      cat: {
        product_category: []
      },
      curProCatCode: '',
      // 搜索关键词
      keywords: '',
      // 排序
      orderby: 'a.update_time desc',
      orderbyList: [
        { name: '更新时间', value: 'a.update_time desc' },
        { name: '库存数量', value: 'a.store_count asc' },
      ],
      inventoryData: [],
      // 新增
      showAddDialog: false,
      // 任务详情
      showTaskDialog: false,
      showCompareDialog: false,
      projectOid: '',
      page: {
        // 当前页
        page_index: 1,
        // 当前条
        page_size: 10,
        // 总条
        total: 0
      },
    }
  },
  created() {
    this.backup = JSON.parse(JSON.stringify(this.form))
    this.getInventoryData()
    this.getCatList('product_category', 1)
  },
  computed: {
    dayjs() {
      return dayjs
    },
    pagination() {
      return {
        current: this.page.page_index,
        pageSize: this.page.page_size,
        total: this.page.total,
        showSizeChanger: true,
        hideOnSinglePage: false
      }
    }
  },
  methods: {
    addCat(row, edit, open, fun) {
      this.form = {
        ...this.backup,
        level: row.level - 0 + 1,
        routemap: row.routemap + '/' + row.oid,
        title: edit ? '编辑产品类目' : '新增产品类目',
        cat_name: edit ? row.cat_name : '',
        cat_code: edit ? row.cat_code : '',
        oid: edit ? row.oid : ''
      }
      if (typeof fun === 'function') this.addOpen = fun
      this.editFolder = Object.assign(row, { open: open })
      this.visible = true
    },
    delCat(row) {
      this.$confirm({
        title: '确定要删除吗?',
        onOk: () => {
          fetch('post', '/cpy/cat/delete', {
            oid: row.oid
          }).then((res) => {
            if (res.status === 0) {
              this.queryTree(this.cat.product_category, row.oid, (datas, item, index) => {
                console.log(datas, item, index);
                this.$delete(datas, index);
                this.$refs.edpTree.$forceUpdate()
              })
              this.$message.success('删除成功！')
            } else {
              this.$message.error(res.message)
            }
          })
        },
        onCancel() {
        },
      });
    },
    showChild(row) {
      console.log(this.editFolder);
      console.log(row);
      if (!this.editFolder.children) this.editFolder.children = []
      this.editFolder.children.push(row)
      if (this.editFolder.open && typeof this.addOpen === 'function') {
        this.addOpen(this.editFolder.oid)
      }
    },
    queryTree(datas, oid, cb) { // 递归循环查树结构元素
      datas.forEach((item, index) => {
        if (item.oid === oid) {
          cb(datas, item, index)
          return;
        }
        if (item.children) {  //存在子节点就递归
          this.queryTree(item.children, oid, cb);
        }
      })
    },
    submit() {
      let url = this.form.title === '编辑产品类目' ? '/cpy/cat/update' : '/cpy/cat/create'
      fetch('post', url, this.form).then((res) => {
        if (res.status === 0) {
          this.visible = false
          this.queryTree(this.cat.product_category, res.data.oid, (datas, item) => {
            this.$set(item, 'cat_name', res.data.cat_name)
          })
          if (this.form.title === '新增产品类目') this.showChild(res.data)
          this.$message.success(this.form.title + '成功！')
        } else {
          this.$message.error(res.message)
        }
      })
    },

    // 点击产品类别
    toTop() {
      this.curProCatCode = ''
      this.getInventoryData()
    },

    // 获取类别
    getCatList(cat_type, level) {
      fetch("post", "/cpy/cat/retrieve", { cat_type, level }).then(res => {
        if (res.status === 0) {
          this.cat.product_category = res.data.datas
        } else {
          this.$message.error(res.message)
        }
      })
    },

    // 获取子集类别
    getCatChildren(row) {
      console.log(row);
      let { cat_type, level, routemap, oid } = row
      fetch('post', '/cpy/cat/retrieve', {
        cat_type,
        level: level - 0 + 1,
        routemap: routemap + '/' + oid
      }).then((res) => {
        if (res.status === 0) {
          console.log('子级文件夹', res.data);
          if (res.data.datas.length > 0) {
            this.$set(row, 'children', res.data.datas)
          }
        } else {
          this.$message.error(res.message)
        }
      })
    },

    clickTree(row) {
      console.log(row);
      this.curProCatCode = row.cat_code
      this.getInventoryData()
    },

    // 获取库存列表数据
    async getInventoryData() {
      const res = await fetch('post', '/whs/retrieve', {
        product_category: this.curProCatCode,
        keywords: this.keywords,
        orderby: this.orderby,
        page_index: this.page.page_index,
        page_size: this.page.page_size
      })
      if (res.status === 0) {
        this.inventoryData = res.data.datas
        this.page.total = res.data.page.total
      } else {
        this.$message.error(res.message)
      }
    },

    // 页码跟条数发生变化
    paginationChange(pagination) {
      this.page.page_index = pagination.current
      this.page.page_size = pagination.pageSize
      this.getInventoryData()
    },

    // 新增库存
    showAddInventoryDialog() {
      this.showAddDialog = true
    },
    // 打开任务详情
    showTaskDetailDialog(row) {
      this.projectOid = row.prooid
      this.showTaskDialog = true
      this.$refs.taskDetailDialog.getPageData(row.prooid)
    },
    // 删除库存
    deleteInventory(oid) {
      this.$confirm({
        title: `确定删除?`,
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk: async () => {
          const res = await fetch('post', '/whs/delete', { oid })
          if (res.status === 0) {
            this.$message.success('删除成功')
            await this.getInventoryData()
          } else {
            this.$message.error(res.message)
          }
        },
        onCancel() {
        }
      })
    },

    // 提交新增库存
    addSubmit(form) {
      console.log(form)
    },

    // 排序
    changeInventoryData() {
      this.page.page_index = 1
      this.getInventoryData()
    },

    // 查询
    searchInventoryData() {
      this.page.page_index = 1
      this.getInventoryData()
    },

    // 导入导出模板
    selectActionMenu({ key }) {
      if (key === "import") {
        this.$refs.importData.upload()
      } else if (key === "export") {
        // console.log(this.$toUrl(`/pro/export?oids=${this.proList.map(item => item.oid).join(',')}`, true, "api"));
        if (this.inventoryData.length > 0) {
          window.open(this.$toUrl(`/whs/export?product_category=${this.curProCatCode}&keywords=${this.keywords}&orderby=${this.orderby}`, true, "api"))
        } else {
          this.$message.error('暂无产品')
        }
      } else if (key === "download") {
        getTemplate('/attachment/model', '库存导入导出模版.xlsx')
      } else if (key === 'compare') {
        this.$refs.importCompareData.upload()
      }
    },

    // 导入对比模板的回调
    compareUploadResponse(res) {
      console.log(res)
      if (res.status === 0) {
        const data = res.data.map((item, index) => {
          return { ...item, number: index + 1 }
        })
        this.$refs.compareResultsDialog.getData(data)
        this.showCompareDialog = true
      } else {
        this.$message.error(res.message)
      }

    },

    // 导入模板的回调
    uploadResponse(res) {
      if (res.status === 0) {
        this.getInventoryData()
      } else {
        this.$message.error(res.message)
      }
    },
  },
}
</script>

<style lang="less" scoped>
.app-main-content {
  display: flex;

  .left-category {
    display: flex;
    flex-direction: column;
    height: 100%;

    .stage-left {
      width: 300px;
      height: 100%;
      margin-right: 15px;

      .tree-row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        font-size: 16px;
        font-weight: 500;

        .tree-row-info {
          display: flex;
        }

        .tree-row-dropdown {
          padding-right: 5px;
        }
      }
    }
  }

  .right-table {
    flex: 1;
    height: 100%;
    overflow: hidden;
    box-shadow: 0 2px 10px 0 rgba(182, 182, 182, 0.5);

    /deep/ .page-card-body {
      height: 100%;
      padding: 0 20px;
      display: flex;
      flex-direction: column;
      overflow-y: auto;
    }
  }
}
</style>
