<template>
  <a-modal
      class="taskModal"
      title="对比结果"
      width="1048px"
      :body-style="{padding: 0}"
      :visible="showDialog"
      :maskClosable="false"
      @cancel="closeDialog"
  >
    <div class="content">
      <a-table :columns="columns" :data-source="data" :indentSize="2" defaultExpandAllRows :expandIconColumnIndex="1"
               :pagination="false" rowKey="seqid">
        <template #name="text, record">
          <span :style="{ fontWeight: record.children ? '700' : '400',color:'#4d4d4d' }"
                :class="{'bold-text': record.children}">{{ record.product_name }}</span>
        </template>
      </a-table>
    </div>
    <template #footer>
      <div class="modal-footer" style="display: flex; align-items: center">
        <a-checkbox v-model="next" style="margin-right: auto;">
          继续对比下一个
        </a-checkbox>
        <a-space>
          <a-button @click="closeDialog">
            取消
          </a-button>
          <a-button type="primary" @click="submit">
            确定
          </a-button>
        </a-space>
      </div>
    </template>
  </a-modal>
</template>

<script>
const columns = [
  { title: '序号', dataIndex: 'number', width: 70 },
  { title: '名称', dataIndex: 'product_name', scopedSlots: { customRender: 'name' } },
  { title: '编号', dataIndex: 'product_code', width: 180 },
  { title: '规格型号', dataIndex: 'tech_info', width: 200 },
  { title: '库存', dataIndex: 'store_count', width: 80 },
  { title: '单位', dataIndex: 'unit', width: 80 },
  { title: '品牌', dataIndex: 'band', width: 180 },
]

export default {
  props: {
    showDialog: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      columns,
      data: [],
      // 是否继续创建
      next: false
    }
  },
  methods: {
    // 关闭弹窗
    closeDialog() {
      this.$emit('update:showDialog', false)
      this.data = []
    },
    // 点击确认
    submit() {
      if (this.next) {
        this.$parent.$refs.importCompareData.upload()
      } else {
        this.closeDialog()
      }
    },
    getData(data) {
      this.data = data
    }
  },
}
</script>

<style lang="less" scoped>
.taskModal {
  /deep/ .ant-modal-body {
    height: 600px;

    .content {
      padding: 20px;

      .ant-table {
        .ant-table-body {
          table {
            border-left: none !important;

            .ant-table-thead {
              tr {
                th {
                  font-size: 16px !important;
                  border-right: none !important;
                  border-left: none !important;
                }
              }
            }

            .ant-table-tbody {
              tr {
                td {
                  font-size: 14px;
                  border-right: none !important;

                  &:last-child {
                    //border-right: 1px solid #EDEDED !important;
                  }

                  .ant-table-row-expand-icon.ant-table-row-expanded {
                    display: none;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
